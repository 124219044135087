// Bootstrap overrides

//
// Color system
//

$white: #fff;
$gray-100: #f8f9fb; // gray-lightest
$gray-200: #e9ecef; // gray-lighter
$gray-300: #bcc4d4; // gray-light
$gray-400: #a6aebe; // gray
$gray-500: #839ba8;
$gray-600: #7f8fa4;
$gray-700: #5a6269;
$gray-800: #1b2a4e; // mix(#12263f, #262f3d);
$gray-900: #060707;
$black: #000;

$blue: #0077c2;
$indigo: #6610f2;
$purple: #4e3071;
$pink: mix(#e27f56, #f08431);
$red: #d94a3d;
$orange: #e6bc33;
$yellow: #ffc107;
$green: #50b652;
$teal: #0077c2;
$cyan: #6fb5f3;

$colors: (
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'black': $black,
  'white': $white,
  'gray': $gray-600,
  'gray-dark': $gray-800,
);

$primary: $purple;
$secondary: $cyan;
$success: $green;
$info: $blue;
$warning: $orange;
$danger: $red;
$light: $gray-100;
$medium: $gray-600;
$dark: $gray-900;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  medium: $medium,
  dark: $dark,
);
